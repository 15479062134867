var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"iot_input",staticClass:"h100 gridbox-element"},[_c('Transition',{attrs:{"name":"fade"}},[(_vm.isLEDFlow == '1' && !_vm.isLoading)?_c('div',{staticClass:"flex flex-column h100 justify-between"},[_c('div',{staticClass:"flex flex-column",staticStyle:{"flex-grow":"1"}},[_c('h2',{class:`${
            _vm.invertedColors ? 'color-black' : 'color-white'
          } h0 open-sans-bold center mb-mb4 mb1`},[_vm._v(" "+_vm._s(_vm.flow.question)+" ")]),_c('tabcarousel',{attrs:{"slide1Heading":_vm.$t('iot_red_sea_system_select_placeholder'),"slide2Heading":_vm.$t('iot_tab_heading_custom_tank'),"invertedColors":_vm.invertedColors}},[_c('div',{attrs:{"slot":"slide-1"},slot:"slide-1"},[_c('div',[_c('div',{staticClass:"iot-select-wrapper mx-auto mb3 relative"},[_c('svg',{staticClass:"pointer-events-none",attrs:{"width":"12","height":"8","viewBox":"0 0 12 8","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M10.7695 1.5L5.88481 6.5L1.00008 1.5","stroke":_vm.invertedColors ? 'black' : 'white',"stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedTank),expression:"selectedTank"}],class:`iot-select h5 open-sans-regular pointer ${
                    _vm.invertedColors
                      ? 'color-14 iot-select-dark bg-color-white'
                      : 'iot-select-light color-white'
                  }`,on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedTank=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":"","isSelected":""},domProps:{"value":{}}},[_vm._v(" "+_vm._s(_vm.$t("iot_red_sea_system_select_placeholder"))+" ")]),_vm._l((_vm.flow.tankSelection),function(tank){return _c('option',{key:tank.RNumberOfTank,domProps:{"value":tank}},[_vm._v(" "+_vm._s(tank.Model)+" ")])})],2)]),_c('p',{staticClass:"center color-13 h6 open-sans-regular mb3"},[_vm._v(" "+_vm._s(_vm.$t("iot_g2_g2p_notice"))+" ")]),_c('div',{class:`rsf-btn rsf-btn-primary bg-color-1 mx-auto h5 color-white open-sans-regular ${
                  Object.keys(_vm.selectedTank).length == 0 ? 'inactive' : ''
                }`,on:{"click":function($event){return _vm.displayOutput('rsfTank')}}},[_vm._v(" "+_vm._s(_vm.$t("iot_btn_label_show_results"))+" ")])])]),_c('div',{attrs:{"slot":"slide-2"},slot:"slide-2"},[_c('div',[(_vm.measurement == 'metric')?_c('div',{staticClass:"iot-input-wrapper mx-auto flex flex-column gap1 mb2"},[_c('div',{staticClass:"iot-select-wrapper mx-auto relative col-12"},[_c('svg',{staticClass:"pointer-events-none",attrs:{"width":"12","height":"8","viewBox":"0 0 12 8","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M10.7695 1.5L5.88481 6.5L1.00008 1.5","stroke":_vm.invertedColors ? 'black' : 'white',"stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.width),expression:"width"}],ref:"iot_select_width_metric",class:`iot-select ${
                      _vm.invertedColors
                        ? 'iot-select-dark color-14 bg-color-white'
                        : 'iot-select-light color-white'
                    } h5 open-sans-regular pointer`,on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.width=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":"","isSelected":""},domProps:{"value":0}},[_vm._v(" "+_vm._s(_vm.$t("iot_width_select_placeholder"))+" ")]),_c('option',{domProps:{"value":50}},[_vm._v("50cm")]),_c('option',{domProps:{"value":57}},[_vm._v("57cm")]),_c('option',{domProps:{"value":65}},[_vm._v("65cm")]),_c('option',{domProps:{"value":80}},[_vm._v("80cm")])])]),_c('div',{staticClass:"iot-select-wrapper mx-auto relative col-12"},[_c('svg',{staticClass:"pointer-events-none",attrs:{"width":"12","height":"8","viewBox":"0 0 12 8","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M10.7695 1.5L5.88481 6.5L1.00008 1.5","stroke":_vm.invertedColors ? 'black' : 'white',"stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.length),expression:"length"}],ref:"iot_select_length_metric",class:`iot-select h5 open-sans-regular pointer ${
                      _vm.invertedColors
                        ? 'color-14 iot-select-dark bg-color-white'
                        : 'iot-select-light color-white'
                    }`,on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.length=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":"","isSelected":""},domProps:{"value":0}},[_vm._v(" "+_vm._s(_vm.$t("iot_length_select_placeholder"))+" ")]),_c('option',{domProps:{"value":50}},[_vm._v("50cm")]),_c('option',{domProps:{"value":60}},[_vm._v("60cm")]),_c('option',{domProps:{"value":90}},[_vm._v("90cm")]),_c('option',{domProps:{"value":120}},[_vm._v("120cm")]),_c('option',{domProps:{"value":150}},[_vm._v("150cm")]),_c('option',{domProps:{"value":180}},[_vm._v("180cm")]),_c('option',{domProps:{"value":200}},[_vm._v("200cm")]),_c('option',{domProps:{"value":225}},[_vm._v("225cm")])])])]):_vm._e(),(_vm.measurement == 'imperial')?_c('div',{staticClass:"iot-input-wrapper mx-auto mb2 flex flex-column gap1"},[_c('div',{staticClass:"iot-select-wrapper mx-auto relative col-12"},[_c('svg',{staticClass:"pointer-events-none",attrs:{"width":"12","height":"8","viewBox":"0 0 12 8","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M10.7695 1.5L5.88481 6.5L1.00008 1.5","stroke":_vm.invertedColors ? 'black' : 'white',"stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.width),expression:"width"}],ref:"iot_select_width_imperial",class:`iot-select h5 open-sans-regular pointer ${
                      _vm.invertedColors
                        ? 'color-14 iot-select-dark bg-color-white'
                        : 'iot-select-light color-white'
                    }`,on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.width=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":"","isSelected":""},domProps:{"value":0}},[_vm._v(" "+_vm._s(_vm.$t("iot_width_select_placeholder"))+" ")]),_c('option',{domProps:{"value":50}},[_vm._v("20\"")]),_c('option',{domProps:{"value":57}},[_vm._v("22.6\"")]),_c('option',{domProps:{"value":65}},[_vm._v("25.6\"")]),_c('option',{domProps:{"value":80}},[_vm._v("32\"")])])]),_c('div',{staticClass:"iot-select-wrapper mx-auto relative col-12"},[_c('svg',{staticClass:"pointer-events-none",attrs:{"width":"12","height":"8","viewBox":"0 0 12 8","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M10.7695 1.5L5.88481 6.5L1.00008 1.5","stroke":_vm.invertedColors ? 'black' : 'white',"stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.length),expression:"length"}],ref:"iot_select_length_imperial",class:`iot-select h5 open-sans-regular pointer ${
                      _vm.invertedColors
                        ? 'color-14 iot-select-dark bg-color-white'
                        : 'iot-select-light color-white'
                    }`,on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.length=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":"","isSelected":""},domProps:{"value":0}},[_vm._v(" "+_vm._s(_vm.$t("iot_length_select_placeholder"))+" ")]),_c('option',{domProps:{"value":50}},[_vm._v("20\"")]),_c('option',{domProps:{"value":60}},[_vm._v("24\"")]),_c('option',{domProps:{"value":90}},[_vm._v("36\"")]),_c('option',{domProps:{"value":120}},[_vm._v("47\"")]),_c('option',{domProps:{"value":150}},[_vm._v("59\"")]),_c('option',{domProps:{"value":180}},[_vm._v("71\"")]),_c('option',{domProps:{"value":200}},[_vm._v("79\"")]),_c('option',{domProps:{"value":225}},[_vm._v("89\"")])])])]):_vm._e(),_c('p',{staticClass:"center color-13 h6 open-sans-regular mb3"},[_c('span',{staticClass:"open-sans-bold"},[_vm._v(_vm._s(_vm.$t("iot_note")))]),_vm._v(" "+_vm._s(_vm.$t("iot_water_depth_notice"))+" ")]),_c('customswitch',{attrs:{"label1":"cm","label2":"inch","state1":"metric","state2":"imperial","value":_vm.measurement,"ebEvent":"update_measurement","invertedColors":_vm.invertedColors}}),_c('div',{class:`rsf-btn rsf-btn-primary bg-color-1 mx-auto h5 color-white open-sans-regular md-mb5 mb3 mt3 ${
                  _vm.width <= 0 || _vm.length <= 0 ? 'inactive' : ''
                }`,on:{"click":function($event){return _vm.displayOutput('custom')}}},[_vm._v(" "+_vm._s(_vm.$t("iot_btn_label_show_results"))+" ")])],1)])])],1),_c('p',{class:`h6 open-sans-regular center ${
          _vm.invertedColors ? 'color-14' : 'color-white'
        }`},[_vm._v(" "+_vm._s(_vm.$t("iot_see_all"))+" "),_c('a',{staticClass:"open-sans-bold underline",staticStyle:{"text-decoration-thickness":"1px"},attrs:{"target":"_blank","href":_vm.tableLink}},[_vm._v(_vm._s(_vm.$t("iot_compatibility_charts_line")))])])]):_vm._e()]),_c('Transition',{attrs:{"name":"fade"}},[(_vm.isLEDFlow != '1' && !_vm.isLoading)?_c('div',{staticClass:"h100 flex flex-column justify-between"},[_c('div',[_c('h2',{staticClass:"color-white h0 open-sans-bold center mb4"},[_vm._v(" "+_vm._s(_vm.flow.question)+" ")]),_c('div',{staticClass:"iot-select-wrapper mx-auto mb3 relative"},[_c('svg',{staticClass:"pointer-events-none",attrs:{"width":"12","height":"8","viewBox":"0 0 12 8","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M10.7695 1.5L5.88481 6.5L1.00008 1.5","stroke":_vm.invertedColors ? 'black' : 'white',"stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedTank),expression:"selectedTank"}],class:`iot-select h5 open-sans-regular pointer ${
              _vm.invertedColors
                ? 'color-14 iot-select-dark'
                : 'iot-select-light color-white'
            }`,on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedTank=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":"","isSelected":""},domProps:{"value":{}}},[_vm._v(" "+_vm._s(_vm.$t("iot_red_sea_system_select_placeholder"))+" ")]),_vm._l((_vm.flow.tankSelection),function(tank){return _c('option',{key:tank.RNumberOfTank,domProps:{"value":tank}},[_vm._v(" "+_vm._s(tank.Model)+" ")])})],2)]),_c('p',{staticClass:"center color-white h6 open-sans-regular mb3"},[_vm._v(" "+_vm._s(_vm.$t("iot_g2_g2p_notice"))+" ")]),_c('div',{class:`rsf-btn rsf-btn-primary bg-color-1 mx-auto h5 color-white open-sans-regular mb5 ${
            Object.keys(_vm.selectedTank).length == 0 ? 'inactive' : ''
          }`,on:{"click":function($event){return _vm.displayOutput('rsfTank')}}},[_vm._v(" "+_vm._s(_vm.$t("iot_btn_label_show_results"))+" ")])]),_c('p',{class:`h6 ${
          _vm.invertedColors ? 'color-black' : 'color-white'
        } open-sans-regular center`},[_vm._v(" "+_vm._s(_vm.$t("iot_see_all"))+" "),_c('a',{staticClass:"open-sans-bold underline",staticStyle:{"text-decoration-thickness":"1px"},attrs:{"target":"_blank","href":_vm.tableLink}},[_vm._v(_vm._s(_vm.$t("iot_compatibility_charts_line")))])])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }