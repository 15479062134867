<template>
  <div
    id="iot_wrapper"
    class="gridbox iot-layout-wrapper"
    :style="`min-height: ${
      wrapperHeight > 0
        ? wrapperHeight + 'px'
        : 'calc(100dvh - var(--top-menu-height))'
    }`"
  >
    <div
      v-if="bgImgSrc"
      class="gridbox-element relative z1 bg-color-black lg-py5 py4 px2"
    >
      <img :src="bgImgSrc" :alt="bgImgAlt" />
    </div>
    <div
      class="gridbox-element relative z2 lg-py5 py4 max-width-3 mx-auto gridbox px2"
    >
      <Transition name="fade">
        <slot></slot>
      </Transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "layout",
  props: {
    appHeight: Number,
    bgImgSrc: String,
    bgImgAlt: String,
  },
  data() {
    return {
      wrapperHeight: 0,
    };
  },
  mounted() {},
  watch: {
    appHeight() {
      console.log("appheight changed");
      this.wrapperHeight = this.appHeight;
    },
  },
};
</script>

<style scoped>
img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  object-fit: cover;
  opacity: 0.4;
  height: 100%;
}
.iot-layout-wrapper {
  min-height: calc(100dvh - var(--top-menu-height));
}
</style>
