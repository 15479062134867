<template>
  <layout :appHeight="appHeight" :bgImgSrc="bgImgSrc" :bgImgAlt="bgImgAlt">
    <router-view
      v-if="!isLoading"
      :iotId="iotId"
      :flow="flow"
      :siteLanguage="siteLanguage"
      :isLEDFlow="isLEDFlow"
      :customTankIotId="customTankIotId"
      :measurement="measurement"
      :recommendations="recommendations"
      :selectedTank="selectedTank"
      :tableLink="tableLink"
      :invertedColors="invertedColors == '1' ? true : false"
      :disclaimer="disclaimer"
    />
    <loader
      v-if="isLoading"
      :invertedColors="invertedColors == '1' ? true : false"
    />
  </layout>
</template>

<script>
import axios from "axios";
import { EventBus } from "./event-bus";
import layout from "./components/layout.vue";
import loader from "./components/loader.vue";

export default {
  name: "App",
  props: [
    "siteLanguage",
    "iotId",
    "customTankIotId",
    "isLEDFlow",
    "tableLink",
    "bgImgSrc",
    "bgImgAlt",
    "invertedColors",
    "disclaimer",
  ],
  components: {
    layout,
    loader,
  },
  data() {
    return {
      measurement: "metric",
      flow: {},
      selectedTank: {},
      customTankWidth: 0,
      customTankLength: 0,
      displayTankWidth: 0,
      displayTankLength: 0,
      showLog: true,
      isLoading: false,
      recommendations: [],
      appHeight: 0,
    };
  },
  created() {
    let that = this;

    if (this.siteLanguage == "") {
      this.$i18n.locale = "en";
      this.switchLocale();
    } else {
      this.$i18n.locale = this.siteLanguage;
      this.switchLocale();
    }

    //state manager
    EventBus.$on("eb_log", (component, msg, method) => {
      this.EBlog(component, msg, method);
    });
    EventBus.$on("update_flow", (payload) => {
      EventBus.$emit("eb_log", this.$options.name, "update_flow", "on");
      this.flow = payload;

      //switch the place of some tanks
      let s550g2p = {}; //tank to switch place, rnumber: R43100G2P
      let s700g2p = {}; //tank to switch place, rnumber: R43200G2P

      // tank to put the others under, rnumber: R42441G2P
      let tankArray = Object.values(this.flow.tankSelection);
      for (let i = 0; i < tankArray.length; i++) {
        if (tankArray[i].RNumberOfTank == "R43100G2P") {
          s550g2p = tankArray[i];
          tankArray.splice(i, 1);
        }
        if (tankArray[i].RNumberOfTank == "R43200G2P") {
          s700g2p = tankArray[i];
          tankArray.splice(i, 1);
        }
      }

      let updatedTankArray = tankArray;
      let indexToInsertAfter = 0;

      for (let x = 0; x < updatedTankArray.length; x++) {
        if (updatedTankArray[x].RNumberOfTank == "R42441G2P") {
          indexToInsertAfter = x;
          break;
        }
      }

      tankArray.splice(indexToInsertAfter + 1, 0, s550g2p);
      tankArray.splice(indexToInsertAfter + 2, 0, s700g2p);

      this.flow.tankSelection = tankArray; //format object into array
    });
    EventBus.$on("update_customTankDimensions", (payload) => {
      EventBus.$emit(
        "eb_log",
        this.$options.name,
        "update_customTankDimensions",
        "on"
      );
      this.customTankWidth = payload.width;
      this.customTankLength = payload.length;
      this.displayTankWidth = payload.displayWidth;
      this.displayTankLength = payload.displayLength;
      const fetchParams = {
        rNumber: "",
        customTankWidth: this.customTankWidth,
        customTankLength: this.customTankLength,
        displayTankWidth: this.displayTankWidth,
        displayTankLength: this.displayTankLength,
        measurement: this.measurement,
        iotId: this.customTankIotId,
      };
      this.fetchRecommendation(fetchParams);
    });
    EventBus.$on("update_selectedTank", (payload) => {
      EventBus.$emit("eb_log", this.$options.name, "update_selectedTank", "on");
      this.selectedTank = payload;
      const fetchParams = {
        rNumber: this.selectedTank.RNumberOfTank,
        customTankWidth: 0,
        customTankLength: 0,
        measurement: this.measurement,
        iotId: this.iotId,
      };
      this.fetchRecommendation(fetchParams);
    });
    EventBus.$on("update_measurement", (payload) => {
      EventBus.$emit("eb_log", this.$options.name, "update_measurement", "on");
      if (payload.currentValue == payload.state1) {
        this.measurement = payload.state2;
      } else {
        this.measurement = payload.state1;
      }
    });
  },
  mounted() {
    console.log("vue app mounted");

    let that = this;
    this.getNavBarHeight();
    window.addEventListener("resize", () => {
      that.getNavBarHeight();
    });
    this.getHeight();
  },
  methods: {
    log(msg) {
      if (!this.showLog) {
        return;
      }
      console.log(msg);
    },
    EBlog(component, msg, method) {
      if (!this.showLog) {
        return;
      }
      console.log("++++++");
      if (method == "on") {
        console.log(
          "EventBus:on --- " + component + " received event: " + msg + "."
        );
      }
      if (method == "emit") {
        console.log(
          "EventBus:emit --- " + component + " emitted event: " + msg + "."
        );
      }
      console.log("++++++");
    },
    getNavBarHeight() {
      let navBar = document.getElementsByClassName("rsf-nav");
      let secondBar = document.getElementsByClassName("rsf-second-navbar");
      let navBarHeight = 0;
      let secondBarHeight = 0;
      if (navBar.length > 0) {
        navBarHeight = navBar[0].offsetHeight;
      }
      if (secondBar.length > 0) {
        secondBarHeight = secondBar[0].offsetHeight;
      }
      let navHeight = navBarHeight + secondBarHeight + 1;
      document.documentElement.style.setProperty(
        "--top-menu-height",
        navHeight + "px"
      );
    },
    preSelectCountry() {
      console.log("preselect country");
      for (let i = 0; i < this.countries.length; i++) {
        if (this.countries[i].preselection_by_ip) {
          console.log("prselected by IP!");
          console.log(this.countries[i].isocode2);
          this.selectedCountry = this.countries[i];
          this.updateCountry(this.countries[i]);
          if (this.countries[i].isocode2 == "US") {
            console.log("us - switch to imperial");
            this.measurement = "imperial";
          } else {
            console.log("use metric system");
            this.measurement = "metric";
          }
          return;
        }
      }

      console.log("no preselection by ip, checking site locale");

      if (this.siteLanguage == "cn") {
        this.findCountry("CN");
        return;
      } else if (this.$i18n.locale == "de") {
        this.findCountry("DE");
      } else if (this.$i18n.locale == "fr") {
        this.findCountry("FR");
      } else if (this.$i18n.locale == "en") {
        this.findCountry("GB");
      } else if (this.$i18n.locale == "br") {
        this.findCountry("GB");
      } else if (this.$i18n.locale == "ja") {
        this.findCountry("GB");
      } else {
        console.log("use fall back country");
        this.findCountry("US"); // fallback US
      }
    },
    findCountry(isocode) {
      console.log("find country: " + isocode);
      for (let a = 0; a < this.countries.length; a++) {
        if (this.countries[a].isocode2 == isocode) {
          this.selectedCountry = this.countries[a];
          this.updateCountry(this.countries[a]);
        }
      }
    },
    switchLocale() {
      var locale = this.$i18n.locale;
      const to = this.$router.resolve({ params: { lang: locale } });
      this.$router.push(to.location).catch((err) => {
        if (
          err.name !== "NavigationDuplicated" &&
          !err.message.includes(
            "Avoided redundant navigation to current location"
          )
        ) {
          console.log(err);
        }
      });
    },
    fetchRecommendation(fetchParams) {
      this.isLoading = true;
      console.log("get recommendations");
      let url = `/public-api/iot/get-recommendations/${fetchParams.iotId}/json`;
      axios
        .post(process.env.VUE_APP_RSF_API + url, fetchParams)
        .then((response) => {
          console.log(response);
          this.$router.push({
            path: "/" + this.siteLanguage + "/output/",
            query: {
              flowType: this.flow.type_id,
              rNumber: fetchParams.rNumber,
              customTankWidth: fetchParams.customTankWidth,
              customTankLength: fetchParams.customTankLength,
              displayTankLength: this.displayTankLength,
              displayTankWidth: this.displayTankWidth,
            },
          });
          this.recommendations = response.data.recommendationsArray;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getHeight() {
      console.log("GET HEIGHT:");
      setTimeout(() => {
        this.appHeight =
          document.getElementById("iot_wrapper").clientHeight + 10;
        console.log(this.appHeight);
      }, 500);
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-active {
  transition-delay: 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
