var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h100 flex flex-column justify-center gridbox-element"},[_c('div',{staticClass:"h100 flex flex-column justify-between"},[_c('div'),_c('div',[_vm._l((_vm.recommendations),function(recommendation,index){return (_vm.recommendations.length > 0)?_c('p',{key:index,class:`h1 center ${
          _vm.invertedColors ? 'color-black' : 'color-white'
        } open-sans-bold mb1`},[_vm._v(" "+_vm._s(index > 0 && index != _vm.recommendations.length + 1 ? _vm.$t("iot_recommendation_or") : "")+" "+_vm._s(recommendation.Amount)+" "+_vm._s(recommendation.Amount > 1 ? _vm.$t("iot_recommendation_unit_plural") : _vm.$t("iot_recommendation_unit_singular"))+" "+_vm._s(recommendation.Model)+" ")]):_vm._e()}),(_vm.recommendations.length == 0)?_c('p',{class:`h1 center ${
          _vm.invertedColors ? 'color-black' : 'color-white'
        } open-sans-bold mb1`},[_vm._v(" "+_vm._s(_vm.$t("iot_recommendation_no_product_found"))+" ")]):_vm._e(),_c('p',{class:`open-sans-semibold h5 center ${
          _vm.invertedColors ? 'color-14' : 'color-white'
        }`},[_vm._v(" "+_vm._s(_vm.$t("iot_recommendation_for_your"))+" "+_vm._s(_vm.$route.query.customTankWidth == "0" ? _vm.selectedTank.Model : _vm.$t("iot_recommendation_tank"))+" ")]),(_vm.$route.query.customTankWidth != '0')?_c('p',{class:`open-sans-semibold h5 center ${
          _vm.invertedColors ? 'color-14' : 'color-white'
        }`},[_vm._v(" ("+_vm._s(_vm.$t("iot_recommendation_width") + " " + _vm.$route.query.displayTankWidth)+", "+_vm._s(_vm.$t("iot_recommendation_height") + " " + _vm.$route.query.displayTankLength)+") ")]):_vm._e(),(_vm.disclaimer && _vm.showDisclaimer)?_c('p',{class:`center h5 open-sans-regular col-12 mt4 ${
          _vm.invertedColors ? 'color-14' : 'color-white'
        }`},[_vm._v(" "+_vm._s(_vm.disclaimer)+" ")]):_vm._e(),_c('div',{staticClass:"flex flex-column sm-flex gap3 items-center justify-center mt4"},[_c('a',{staticClass:"rsf-btn bg-color-1 rsf-btn-primary color-white open-sans-semibold h5",attrs:{"href":`https://g1.redseafish.com/${_vm.siteLanguage}/super-store-finder/`}},[_vm._v(_vm._s(_vm.$t("iot_recommendation_find_nearest_dealer")))]),_c('router-link',{staticClass:"rsf-btn rsf-btn-tertiary bg-color-transparent color-1 open-sans-semibold h5",attrs:{"to":`/${_vm.$i18n.locale}`}},[_vm._v(_vm._s(_vm.$t("iot_recommendation_start_over")))])],1)],2),_c('p',{class:`h6 ${
        _vm.invertedColors ? 'color-14' : 'color-white'
      } open-sans-regular center`},[_vm._v(" "+_vm._s(_vm.$t("iot_see_all"))+" "),_c('a',{staticClass:"open-sans-bold underline",staticStyle:{"text-decoration-thickness":"1px"},attrs:{"target":"_blank","href":_vm.tableLink}},[_vm._v(_vm._s(_vm.$t("iot_compatibility_charts_line")))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }