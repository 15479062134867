import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import VueI18n from "vue-i18n";
import messages from "./lang";
import Routes from "./routes";

Vue.config.productionTip = false;

Vue.use(VueI18n);
Vue.use(VueRouter);

const router = new VueRouter({
  routes: Routes,
});

export const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages,
});

new Vue({
  i18n,
  render: function (h) {
    return h(App, {
      props: {
        siteLanguage:
          "" + document.getElementById("app").attributes.siteLanguage.value,
        iotId: "" + document.getElementById("app").attributes.iotId.value,
        customTankIotId:
          "" + document.getElementById("app").attributes.customTankIotId.value,
        isLEDFlow:
          "" + document.getElementById("app").attributes.isLEDFlow.value,
        tableLink:
          "" + document.getElementById("app").attributes.tableLink.value,
        bgImgSrc: "" + document.getElementById("app").attributes.bgImgSrc.value,
        bgImgAlt: "" + document.getElementById("app").attributes.bgImgAlt.value,
        invertedColors:
          "" + document.getElementById("app").attributes.invertedColors.value,
        disclaimer:
          "" + document.getElementById("app").attributes.disclaimer.value,
      },
    });
  },
  router: router,
}).$mount("#app");
